import { Button, Stack } from '@mui/material'
import { AccordionDetail, PaginationAccordion } from 'components/Accordion'
import { useAccountMutaion } from 'hooks/mutation/account'
import { useLoginUser } from 'hooks/loginuser'

const AccountListForPad = ( { data } ) => {
    const { mutate : mutateAccount } = useAccountMutaion()
    const { data : loginUserData } = useLoginUser()

    const handleDisableEnable = (d) =>{
        mutateAccount({
            アカウント無効化 : !d.アカウント無効化,
            id : d.id,
            revision : d.revision,
        })
    }

    return (
        <PaginationAccordion
            max={10}
            data={data}
            keyName="メール"
            summaryName="姓名"
            detail={d => (
                <Stack
                    gap={0.5}
                >
                    <AccordionDetail label="氏名" value={`${d.姓} ${d.名}`} />
                    <AccordionDetail label="所属" value={d.所属部署} />
                    <AccordionDetail label="役職" value={d.役職} />
                    <AccordionDetail label="メールアドレス" value={d.メール} />
                    <AccordionDetail label="種別" value={d.アカウント種類} />
                    <AccordionDetail label="通知設定" value={d.メール配信対象 ? '◯' : '✕'} />
                    <AccordionDetail label="状態" value={d.アカウント無効化 ? '無効' : ''} />

                    {(loginUserData?.isAdmin && loginUserData?.メールアドレス !== d.メール) &&
                    <Stack
                        direction="row"
                        alignItems="right"
                        justifyContent="flex-end"
                        gap={1}
                    >
                        <Button
                            onClick={() => handleDisableEnable(d)}
                        >
                            {d.アカウント無効化 ? '有効化' : '無効化'}
                        </Button>
                    </Stack>
                    }
                </Stack>
            )}
        />
    )
}

export default AccountListForPad
