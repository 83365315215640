import { useCallback } from 'react'
import { Link, useParams, useNavigate, useBeforeUnload } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { Stack } from '@mui/material'
import { red } from '@mui/material/colors'
import { isAfter } from 'date-fns'
import { messages } from 'config/messages'
import Block from 'components/Block'
import { SelectPDF } from 'components/FileUploader'
import { CharacterLimitField, Required, ImageCaption } from 'components/Inputs'
import InquiryUserInput from 'components/InquiryUserInput'
import { BlueOutlinedButton, LinkButtonLarge } from 'components/styledButtons'
import { H2, H3, H4, H5, Body } from 'components/styledFonts'
import { useEntryMutation } from 'hooks/mutation/research'


const buttonStyle = {
    width : 240,
    height : 44,
}

const Answer = ({ n, data, control, watch, fromNetamoto }) => {
    const q = `質問${n + 1}${fromNetamoto ? '_ネタもとからの質問' : ''}`
    const a = `回答${n + 1}${fromNetamoto ? '_ネタもとからの質問' : ''}`
    const r = `備考${n + 1}${fromNetamoto ? '_ネタもとからの質問' : ''}`

    if (data[q]) {
        return <CharacterLimitField
            key={n}
            label={`${data[q]}`}
            name={a}
            remarks={data[r]}
            limit={2000}
            control={control}
            required={data[q]}
            multiline
            minRows={4}
            watch={watch(a)}
        />
    }

    return null
}

const EntryComponent = ({ data }) => {
    const { requestNo, entryNo } = useParams()
    const { control, setValue, getValues, handleSubmit, watch } = useFormContext()
    const navi = useNavigate()
    const { mutate } = useEntryMutation()
    const isClosed = isAfter(new Date(), data?.募集締切日時)

    useBeforeUnload((e) => {
        e.preventDefault()
    })

    const saveDraft = useCallback(
        async () => {
            setValue('下書き', true)
            mutate({
                リクエストNo: requestNo,
                entry: getValues(),
            }, {
                onSuccess: (ret) => setValue('$revision', ret.revision),
            })
        },
        [mutate, requestNo, getValues, setValue]
    )

    const targetQuestionList = Object.keys(data || {})
        .filter(k => /^質問\d+$/.test(k) && data[k])
        .map(k => Number(k.replace('質問', '')))

    const targetNetamotoQuestionList = Object.keys(data || {})
        .filter(k => /^質問\d+_ネタもとからの質問/.test(k) && data[k])
        .map(k => Number(k.replace(/[^0-9]/g, '')))

    const inquiryUserInput = 
`企業情報設定と異なる方を問い合わせ担当者にする際は入力してください
※マッチング成立になった場合、問い合わせ担当者に連絡します。
必要であれば、マッチング成立連絡について社内共有していただきますようご協力お願いいたします`

    return (
        <Stack>
            <Block>
                {(!isClosed && (entryNo || data?.エントリー.length < 3)) &&
                <Stack
                    component="form"
                    onSubmit={handleSubmit(() => navi(`/research/entry/${requestNo}/preview`))}
                    gap={2}
                >
                    <H2>リサーチ エントリー</H2>
                    {data &&
                    <Stack
                        maxWidth={800}
                        gap={4}
                        sx={{ pl: { sm: 2, xs: 0 } }}
                    >
                        <H3>リサーチに該当する御社の情報について、詳しくご紹介ください。</H3>
                        <CharacterLimitField
                            name="タイトル"
                            limit={100}
                            control={control}
                            required
                            multiline
                            disableEnter
                            watch={watch('タイトル')}
                        />
                        <Stack
                            gap={1}
                        >
                            <H5>メイン画像<Required /></H5>
                            <Body>※一覧のページでサムネイルとしても表示されます。画像は最大5MBまでアップロード可能です。</Body>
                            <ImageCaption
                                name="メイン画像"
                                captionName="メイン画像キャプション"
                                pict={watch('メイン画像')}
                                control={control}
                                setValue={setValue}
                            />
                        </Stack>
                        {[...Array(20).keys()].map(n => (
                        <Answer
                            key={n}
                            n={n}
                            data={data}
                            control={control}
                            watch={watch}
                        />
                        ))}
                        {[...Array(5).keys()].filter(n => data[`質問${n + 1}_ネタもとからの質問`]).length > 0 &&
                        <Stack>
                            <H4>ネタもとからの質問</H4>
                            <Body>※この回答はメディアの方へは反映されません。</Body>
                        </Stack>
                        }
                        {[...Array(5).keys()].map(n => (
                        <Answer
                            key={n}
                            n={n}
                            data={data}
                            control={control}
                            watch={watch}
                            fromNetamoto
                        />
                        ))}
                        <Stack
                            gap={1}
                        >
                            <H5>画像</H5>
                            <Body>※画像は最大5MBまでアップロード可能です。</Body>
                            {[...Array(4).keys()].map(i => (
                            <ImageCaption
                                key={i}
                                name={`画像${i + 1}`}
                                captionName={`画像${i + 1}キャプション`}
                                pict={watch(`画像${i + 1}`)}
                                control={control}
                                setValue={setValue}
                            />
                            ))}
                        </Stack>
                        <Stack
                            gap={1}
                        >
                            <H5>PDF</H5>
                            <SelectPDF
                                name="PDF"
                                control={control}
                                file={watch('PDF')}
                                setValue={setValue}
                                showName
                                removable
                            />
                        </Stack>
                        <InquiryUserInput
                            control={control}
                            setValue={setValue}
                            note={inquiryUserInput}
                        />
                    </Stack>
                    }
                    <Stack
                        direction="row"
                        gap={2}
                        justifyContent="center"
                        pt={2}
                        pb={1}
                    >
                        <BlueOutlinedButton
                            label="下書き保存"
                            onClick={saveDraft}
                            sx={buttonStyle}
                        />
                        <BlueOutlinedButton
                            label="確認"
                            type="submit"
                            onClick={() => {
                                // 回答の必須チェックで使用するため、値が設定されている質問Noリストをフォームに追加する
                                setValue('質問', targetQuestionList)
                                setValue('ネタもとからの質問', targetNetamotoQuestionList)
                            }}
                            sx={buttonStyle}
                        />
                    </Stack>
                </Stack>
                }
                {(isClosed || (!entryNo && data?.エントリー.length >= 3)) &&
                <Stack
                    gap={2}
                >
                    <H2>リサーチ エントリー</H2>
                    <H3 sx={{ color: red[400] }}>{isClosed ? messages.ERR_CLOSED_RESEARCH : messages.ERR_ENTRY_LIMIT}</H3>
                </Stack>
                }
            </Block>
            <Stack
                pt={3}
                pb={1}
                alignItems="center"
            >
                <LinkButtonLarge
                    component={Link}
                    to="/research/list"
                >
                    キャンセル
                </LinkButtonLarge>
            </Stack>
        </Stack>
    )
}

export default EntryComponent
