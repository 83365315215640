import { Button } from '@mui/material'
import TableList from 'components/TableList'
import { useAccountMutaion } from 'hooks/mutation/account'
import { useLoginUser } from 'hooks/loginuser'

const headers = [
    { label : 'No.', sx : { width : 50 } },
    { label : '氏名', sx : {} },
    { label : '所属', sx : {} },
    { label : '役職', sx : {} },
    { label : 'メールアドレス', sx : {} },
    { label : '種別', sx : {} },
    { label : '通知設定', sx : { width : 90 } },
    { label : '状態', sx : { width : 90 } },
    { label : '無効化', sx : { width : 70 }, noLabel : true },
]

const AccountList = ({ data }) => {
    const { mutate : mutateAccount } = useAccountMutaion()
    const { data : loginUserData } = useLoginUser()

    const handleDisableEnable = (d) =>{
        mutateAccount({
            アカウント無効化 : !d.アカウント無効化,
            id : d.id,
            revision : d.revision,
        })
    }

    return (
        <TableList
            headers={headers}
            data={
                data?.map((d, i) => ({
                    'No.' : i + 1,
                    氏名 : `${d.姓} ${d.名}`,
                    所属 : d.所属部署,
                    役職 : d.役職,
                    メールアドレス : d.メール,
                    種別 : d.アカウント種類,
                    通知設定 : d.メール配信対象 ? '◯' : '✕',
                    状態 : d.アカウント無効化 ? '無効' : '',
                    無効化 : 
                    <Button
                        onClick={() => handleDisableEnable(d)}
                        sx={{
                            display : loginUserData?.isAdmin && loginUserData?.メールアドレス !== d.メール ? 'block' : 'none',
                            py : 0,
                        }}
                    >
                        {d.アカウント無効化 ? '有効化' : '無効化'}
                    </Button>
                }))
            }
        />
    )
}

export default AccountList
